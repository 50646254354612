<template>
  <div class="swipe">
    <van-swipe ref="swipe" :autoplay="autoplay" :width="470" @change="onSwipeChange" :show-indicators="false" indicator-color="white">
      <van-swipe-item v-for="m,i in datas" :key="i">
          <van-image width="470" height="300" fit="cover"  lazy-load :src="m.fileUrl" />
      </van-swipe-item>
    </van-swipe>
    <a href="javascript:;" @click="onPrev" class="carousel-control-prev height-300"><span class="carousel-control-prev-icon"></span></a>
    <a href="javascript:;" @click="onNext" class="carousel-control-next height-300"><span class="carousel-control-next-icon"></span></a>
  
    <div class="swipe-box">
      <ul class="clearfix" :style="{width: (datas.length * 100) +'px',left: left + 'px'}" >
        <li :class="{'active': current == i}" v-for="m,i in datas" :key="i">
          <van-image @click="onSwipeTo(i)" width="76" height="56" fit="cover" lazy-load :src="m.fileUrl" />
        </li>
      </ul>
    </div>
    <div class="land-tip">
      <img src="@/assets/icon/ptsh.png" class="width-40">
    </div>
  </div>
</template>
<script>
export default {
  props: {},
  data() {
    return {
      current:0,
      left: 0,
      autoplay:6000,
    };
  },
  props: {
    datas: {
      type: Array,
      default: []
    }
  },
  methods: {
    onSwipeChange(i) {
      this.current = i;
      this.autoplay = 6000;
      if(this.current >= 4 && this.datas.length > 5) {
        this.left = -300;
      } else {
        this.left = 0;
      }
    },
    onPrev() {
      this.autoplay = 0;
      this.$refs.swipe.prev();
    },
    onNext() {
      this.autoplay = 0
      this.$refs.swipe.next();
    },
    onSwipeTo(i) {
      this.autoplay = 0
      this.$refs.swipe.swipeTo(i);
    }
  }
};
</script>

<style scoped>
.land-tip{
  position: absolute;
  top:-5px;
  left:30px;
}
.swipe {
  width: 470px;
  position: relative;
}

.swipe-box {
  width:470px;
  height:60px;
  margin-top:10px;
  overflow:hidden;
  position:relative
}

.swipe-box ul {
  height:60px;
  overflow:hidden;
  margin:0 -9px;
  position:absolute;
  top:0;
  left:0
}

.swipe-box ul li{
  width: 80px;
  height:60px;
  float:left;
  margin:0 9px;
  border:2px solid #fff;
  cursor:pointer
}

.swipe-box ul li.active {
	border-color:#00cc00
}
</style>
