<template>
  <div v-if="datas" class="bg-white rounded">
   <router-link :to="'/supply/detail?id=' + datas.id" target="_blank">
      <div class="row land-div my-3">
        <div class="col-12">
          <div class="image" :style="{height: height + 'px',width: width == 0 ? 'auto' : width+ 'px'}"><img v-if="datas.fileUrl" :style="{height: height + 'px',width:width == 0 ? '100%' : width+ 'px'}" :src="datas.fileUrl" /></div>
        </div>
        <div class="col-12 ">
          <div class="bg-white p-3">
            <div class="text-truncate">{{datas.title}}</div>
            <div class="f-14 text-secondary d-flex align-items-center justify-content-between">
                <div>
                  <span class="text-warning f-20">{{datas.price}}</span><span class="ml-1 text-secondary">{{priceUnit}}</span>
                </div>
                <div class="text-right">
                  <span class="text-secondary">{{datas.measureArea}} {{acreageUnit}}</span>
                </div>
            </div>
            <div class="mt-2 f-14 text-secondary clearfix">
                <div v-if="datas.year" class="float-left">
                  <b-icon icon="clock"></b-icon><span class="ml-1">{{datas.year}} 年</span>
                </div>
                <div class="float-right">
                  <b-icon icon="geo-alt"></b-icon><span class="ml-1">{{getAreaValue(datas.area)}}</span>
                </div>
            </div>
          </div>
        </div>
        <div class="land-tip">
          <img src="@/assets/icon/ptsh.png" class="width-40">
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
import {
    mapGetters
} from 'vuex'
export default {
  props:{
    datas: {
      type: Object,
      default: null
    },
    height: {
        type: Number,
        default: 80
    },
    width: {
        type: Number,
        default: 0
    }
  }, 
  computed: {
      ...mapGetters(['area','dict']),
      acreageUnit() {
          let acreageUnit = '亩';
          if(this.datas.acreageUnit) {
              let a = this.dict.acreageUnit.find(x=> x.id == this.datas.acreageUnit);
              if(a) {
                  acreageUnit = a.text;
              }
          }
          
          return acreageUnit;
      },
      priceUnit() {
          let priceUnit = '万元';
          if(this.datas.priceUnit) {
              let a = this.dict.priceUnit.find(x=> x.id == this.datas.priceUnit);
              if(a) {
                  priceUnit = a.text;
              }
          }
          return priceUnit;
      },
      getAreaValue()	{
				return function(code) {
					let value = '';
					if(this.area) {
						let a = this.area.area.find(x => x.code == code)
						if(a) {
							value = a.name;
							let c = this.area.city.find(x => x.code == a.parentCode);
							if(c) {
								value = value + ' ' + c.name;
								let p = this.area.province.find(x => x.code == c.parentCode);
								if(p) {
									value = value + ' ' + p.name;
								}
							}
						}
					}
					return value.replace(' 市辖区','')
				}
			}
    }
}
</script>

<style scoped>
.land-div{
  position: relative;
}
.land-tip{
  position: absolute;
  top:-5px;
  left:30px;
}
.image {
    overflow: hidden;
}
</style>
