<template>
  <div v-if="datas" class="shadow-sm bg-white rounded">
    <router-link :to="'/supply/detail?id=' + datas.id" target="_blank">
        <div class="row">
            <div class="col-12">
                <div class="image" :style="{height: height + 'px',width: width == 0 ? 'auto' : width+ 'px'}"><img v-if="datas.fileUrl" :style="{height: height + 'px',width:width == 0 ? '100%' : width+ 'px'}" :src="datas.fileUrl" /></div>
                <div class="p-2  ">
                <div class="f-14 text-truncate">{{datas.title}}</div>
                <div class="f-14">
                    <span class="text-secondary">{{datas.price}}{{priceUnit}}</span>
                    <span class="float-right text-secondary">{{datas.measureArea}}{{acreageUnit}}</span>
                </div>
                </div>
            </div>
        </div>
    </router-link>
  </div>
</template>

<script>
import {
    mapGetters
} from 'vuex'
export default {
    props: {
        datas: {
            type: Object,
            default: null
        },
        height: {
            type: Number,
            default: 80
        },
        width: {
            type: Number,
            default: 0
        }
    },
    computed: {
        ...mapGetters(['dict']),
        acreageUnit() {
            let acreageUnit = '亩';
            if(this.datas.acreageUnit) {
                let a = this.dict.acreageUnit.find(x=> x.id == this.datas.acreageUnit);
                if(a) {
                    acreageUnit = a.text;
                }
            }
            
            return acreageUnit;
        },
        priceUnit() {
            let priceUnit = '万元';
            if(this.datas.priceUnit) {
                let a = this.dict.priceUnit.find(x=> x.id == this.datas.priceUnit);
                if(a) {
                    priceUnit = a.text;
                }
            }
            return priceUnit;
        }
    }
}
</script>

<style scoped>
.image {
    overflow: hidden;
}
</style>